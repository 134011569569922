.task-idle {
    --background: #f1f1f1;
}

.task-running {
    --background: #4c8dff;
    --color: #fff;
}

.task-success {
    --background: #69bb7b;
    --color: #fff;
}

.task-success ion-card-title,
.task-error ion-card-title,
.task-running ion-card-title {
    --color: #fff;
}

.task-error {
    --background: #fe4c52;
    --color: #fff;
}

.hidden {
    display: none !important;
    visibility: hidden !important;
}
